import React from "react"
import { Link } from "gatsby"

import "./faq-category.scss"

const FaqCategory = ({ faqs, category, categorySlug }) => {

  return (
    <div className="FaqItem u-applyListStyle">
      <h3 className="t-h4 c-accent-dark" >{category}</h3>
      <ul className="t-body2 c-light-primary">
        {
          faqs.map((faqItem, index) => {

            return <li key={index}>
              <Link to={faqItem.fullSlug} className="c-light-primary u-linkHover" dangerouslySetInnerHTML={{__html: faqItem.title}}/>
            </li>
          })
        }
      </ul>
    </div>
  )
}


export default FaqCategory
