
        module.exports.layouts = ` 
 
      ... on Page_Pagebuilder_Content_AddonGrid {
          fieldGroupName
          productsPerPage
          addons {
              ... on AddOn {
                  addOnId
                  title
                  termNames
                  featuredImage {
                      
      sourceUrl
      altText
                  }
                  product {
                      bottomInfo
                      description
                      fieldGroupName
                      price
                      downloadLink
                      subtitle
                      
      pdf {
        title
        mediaItemUrl
      }
    
                  }
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_ArticleGrid {
          fieldGroupName
          title
          description
          articles {
              article {
                  ... on Post {
                      id
                      title
                      fullSlug
                  }
                  ... on Page {
                      id
                      title
                      fullSlug
                  }
              }
              image {
                  
      sourceUrl
      altText
              }
              description
          }
      }
   
 
      ... on Page_Pagebuilder_Content_CallToAction {
          fieldGroupName
          title
          description
          linkTwo {
              url
              title
              target
              relativeUrl
          }
          linkOne {
              url
              title
              target
              relativeUrl
          }
      }
   
 
      ... on Page_Pagebuilder_Content_ComponentGrid {
          fieldGroupName
          title
          backgroundImage {
              
      sourceUrl
      altText                              
          }
          isRound
          description
          components {
              ... on Component {
                  id
                  title
                  uri
                  content
                  featuredImage {
                      
      sourceUrl
      altText
                  }
              }
          }
          
      pdf {
        title
        mediaItemUrl
      }
    
      }
   
 
      ... on Page_Pagebuilder_Content_FaqGrid {
          fieldGroupName
          heroTitle
          faqTitle
          faqDescription
          faqs {
              ... on FaqCategory {
                  faqCategoryId
                  name
                  slug
                  faqs(where: {orderby: {order: ASC, field: MENU_ORDER}}){
                      nodes {
                          title
                          content
                          fullSlug
                      }
                  }
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_HeroVariantOne {
          description
          fieldGroupName
          title
          isMobileTop
          links {
              link {
                  url
                  title
                  target
                  relativeUrl
              }
          }
          backgroundImage {
              
      sourceUrl
      altText
          }
          icon {
              
      sourceUrl
      altText
          }
      }
   
 
      ... on Page_Pagebuilder_Content_HeroVariantTwo {
          description
          fieldGroupName
          title
          links {
              link {
                  url
                  title
                  target
                  relativeUrl
              }
          }
          backgroundImage {
              mediaDetails {
                height
                width
              }
              mediaType
              mimeType
              
      sourceUrl
      altText
          }
          useImageAspectRatio
          icon {
              
      sourceUrl
      altText
          }
      }
   
 
      ... on Page_Pagebuilder_Content_IframeEmbed {
          fieldGroupName
          title
          subtitle
          description
          iframeUrl
      }
   
 
      ... on Page_Pagebuilder_Content_LeftRightTeaser {
          fieldGroupName
          title
          text
          image {
              
      sourceUrl
      altText
          }
          imageIsLeft
          infoItems {
            icon {
              
      sourceUrl
      altText
            }
            text
          }
      }
   
 
      ... on Page_Pagebuilder_Content_PricingTable {
          fieldGroupName
          title
          description
          advancedSupportPrice
          pricingTableItems {
              title
              icon {
                  
      sourceUrl
      altText
              }
              downloadLink
              listInfos {
                  listInfo
                  listInfoInfoText
              }
              licensePrices {
                  price
                  licenseAmount
              }
              isEducational
          }
      }
   
 
      ... on Page_Pagebuilder_Content_PricingTableSupport {
          fieldGroupName
          title
          description
          pricingTableItems {
              title
              icon {
                  
      sourceUrl
      altText
              }
              price
              listInfos {
                  listInfo
                  listInfoInfoText
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_ProductGrid {
          fieldGroupName
          title
          description
          activeSearch
          activePagination
          productsPerPage
          products {
              ... on Training {
                  trainingId
                  title
                  featuredImage {
                      
      sourceUrl
      altText
                  }
                  product {
                      bottomInfo
                      description
                      fieldGroupName
                      price
                      subtitle
                      isTraining
                      
      pdf {
        title
        mediaItemUrl
      }
    
                  }
              }
              ... on Feature {
                  featureId
                  title
                  featuredImage {
                      
      sourceUrl
      altText
                  }
                  product {
                      bottomInfo
                      description
                      fieldGroupName
                      price
                      subtitle
                      isTraining
                      
      pdf {
        title
        mediaItemUrl
      }
    
                      showOnlyPdf
                  }
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_ProjectGrid {
          fieldGroupName
          title
          backgroundImage {
              
      sourceUrl
      altText  
          }
          description
          projects {
              ... on Post {
                  id
                  excerpt
                  title
                  fullSlug
                  featuredImage {
                      
      sourceUrl
      altText
                  }
                  terms {
                      ... on Category {
                          name
                          id
                      }
                  }
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_SimpleText {
          bgColor
          fieldGroupName
          text
          title
          textColor
      }
   
 
      ... on Page_Pagebuilder_Content_Team {
          description
          fieldGroupName
          title
          members {
              name
              link
              image {
                  
      sourceUrl
      altText
              }
          }
      }
   
 
      ... on Page_Pagebuilder_Content_TestimonialCarousel {
          fieldGroupName
          autoplay
          interval
          items {
              company
              text
              name
          }
      }
   
 
      ... on Page_Pagebuilder_Content_VideoTeaser {
          title
          
      pdf {
        title
        mediaItemUrl
      }
    
          description
          fieldGroupName
          video

      }
  `
    