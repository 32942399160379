
const PageTemplateFragment = (isPreview = false, prefix = "WPGraphQL_", layouts) => {
  const imageFragments = isPreview ? `` : `
      fragment GatsbyImageSharpFluid_tracedSVG on ImageSharpFluid {
          tracedSVG
          aspectRatio
          src
          srcSet
          sizes
      }
  `

  return `
    
      ${imageFragments}
      
      fragment PageTemplateFragment on ${prefix}Page {
          pageBuilder {
              fieldGroupName
              content {
                  ${layouts}
              }
          }
      }
`
}

module.exports.PageTemplateFragment = PageTemplateFragment
