import React from "react"

import "./FaqGrid.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import Grid from "@material-ui/core/Grid"
import FaqCategory from "../../components/faq-item"
import GridSearch from "../../components/grid-search"

const FaqGrid = ({ faqs: faqCategories, heroTitle, faqTitle, faqDescription }) => {

  const [state, setState] = React.useState({
    faqItems: faqCategories,
    searchIsActive: false,
  })

  const handleSearch = (e, searchValue) => {
    e.preventDefault()
    const lowercasedSearch = searchValue.toLowerCase()
    // console.log("handleSearch", searchValue)
    setState({
        searchIsActive: searchValue !== "",
        faqItems: searchValue !== "" ? faqCategories.map(categoryItem => {

          const filteredItems = categoryItem.faqs ? categoryItem.faqs.nodes.filter((faqItem) => {

            // Only searches in title not in content.
            return faqItem['title'] ? faqItem['title'].toString().toLowerCase().includes(lowercasedSearch) : false

            // return Object.keys(faqItem).some(key => {
            //     return faqItem[key] ? faqItem[key].toString().toLowerCase().includes(lowercasedSearch) : false
            //   },
            // )
          }) : categoryItem

          return {
            ...categoryItem,
            faqs: {
              nodes: filteredItems
            }
          }
        }) : faqCategories,
      }
    )


    // const result = categoryItems.map(categoryItem => {
    //   return categoryItem.faqs ? categoryItem.faqs.nodes.filter((faqItem) => {
    //
    //     return Object.keys(faqItem).some(key => {
    //         return faqItem[key] ? faqItem[key].toString().toLowerCase().includes(lowercasedSearch) : false
    //       },
    //     )
    //   }) : categoryItem
    // })
    //
    // console.log(result)
    //
    // const faqItems = items.reduce((accumulator, currentValue) => {
    //   return accumulator.concat(currentValue.faqs.nodes)
    // }, [])


    // console.log({
    //   searchIsActive: searchValue !== "",
    //   faqItems: searchValue !== "" ? faqItems.filter((item) => {
    //
    //     const flattenedItems = {
    //       title: item.title,
    //       ...item.product,
    //     }
    //
    //     return Object.keys(flattenedItems).some(key => {
    //         return flattenedItems[key] ? flattenedItems[key].toString().toLowerCase().includes(lowercasedSearch) : false
    //       },
    //     )
    //   }) : faqCategories,
    // })

  }

  return (
    <Section className="FaqGrid Section--vPadding">
      <SectionInner className="FaqGrid-inner">
        <h2 className="Section-title t-h2">{heroTitle}</h2>
        <GridSearch className="FaqGrid-search" handleSearch={handleSearch} allItems={state.faqItems}/>

        <h1 className="Section-titleLeft t-h2">{faqTitle}</h1>
        <div className="FaqGrid-description Section-textLeft t-body2"
             dangerouslySetInnerHTML={{ __html: faqDescription }}/>

        <Grid className="FaqGrid-grid" container spacing={7}>
          {
            state.faqItems.map((categoryItem, index) => {
                const faqsInCategory = categoryItem.faqs && categoryItem.faqs.nodes.length > 0 ? categoryItem.faqs.nodes : []
                return (
                  faqsInCategory.length > 0 &&
                  <Grid key={categoryItem.faqCategoryId} item xs={12} lg={6} xl={4}>
                    <FaqCategory faqs={faqsInCategory} category={categoryItem.name} categorySlug={categoryItem.slug}/>
                  </Grid>
                )
              },
            )
          }
        </Grid>
      </SectionInner>
    </Section>
  )
}

export default FaqGrid
