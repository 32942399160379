import React from "react"

import Page from "./page"

import { gql, useQuery } from "@apollo/client"

const { PageTemplateFragment } = require("./data.js")

const {layouts} = require("../../../.template-cache/preview-data.js")


const GET_PAGE = (layouts) => gql`
    ${PageTemplateFragment(true, '', layouts)}

    query GET_PAGE($pageId:Int) {
        pageBy(pageId: $pageId) {
                ...PageTemplateFragment

                ancestors {
                    ... on Page {
                        fullSlug
                    }
                }
                fullSlug
                slug
                title
                id
                pageId
                link
                content
                uri
                date
                language {
                    slug
                    name
                    locale
                    id
                    code
                }
                translations {
                    title
                    slug
                    uri
                    fullSlug
                    language {
                        locale
                    }
                }
        }
    }
`

const Preview = () => {
  let windowLocation = ''
  let pageID = 47

  if (typeof window !== 'undefined' && window.location) {
    windowLocation = window ? window.location : ''

    let params = (new URL(windowLocation)).searchParams;

    pageID = parseInt(params.get('post') ? params.get('post') : 47);
  }



  const { loading, error, data } = useQuery(GET_PAGE(layouts), { variables: { pageId: pageID } })


  if (loading) return <p>Loading...</p>
  if (error) {
    console.error(error)
    return <p>Error :(</p>
  }

  return <Page data={data}/>
}

export default Preview
