import React, { useEffect } from "react"

import "../../assets/sass/main.scss"
import "typeface-roboto"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { connect } from "react-redux"
import { setCorrespondingLangSlug, setToLanguage } from "../../state/actions"
import { useTranslation } from 'react-i18next';

// Sections
import ProductGrid from "../../layouts/ProductGrid"
import TestimonialCarousel from "../../layouts/TestimonialCarousel"
import SimpleText from "../../layouts/SimpleText"
import Team from "../../layouts/TeamGrid/TeamGrid"
import FaqGrid from "../../layouts/FaqGrid"
import CallToAction from "../../layouts/CallToAction"
import ArticleGrid from "../../layouts/ArticleGrid"
import ProjectGrid from "../../layouts/ProjectGrid"
import VideoTeaser from "../../layouts/VideoTeaser/VideoTeaser"
import ComponentGrid from "../../layouts/ComponentGrid"
import PricingTable from "../../layouts/PricingTable"
import PricingTableSupport from "../../layouts/PricingTableSupport"
import HeroVariantTwo from "../../layouts/HeroVariantTwo"
import Section from "../../components/section"
import SectionOuter from "../../components/section-outer"
import GatsbyImage from "gatsby-image"
import SectionInner from "../../components/section-inner"
import HeroVariantOne from "../../layouts/HeroVariantOne"
import LeftRightTeaser from "../../layouts/LeftRightTeaser"
import AddonGrid from "../../layouts/AddonGrid"
import IframeEmbed from "../../layouts/IframeEmbed"

const Page = ({ setCorrespondingLangSlug, setToLanguage, lang, correspondingLangSlug, data }) => {
  const { i18n } = useTranslation()


  const page = data.pageBy

  const pageBuilder = page.pageBuilder.content || []

  const correspondingTranslation = page.translations.find((translation) => {
    return translation.language.locale !== page.language.locale
  })

  useEffect(() => {
    if (page.language.locale !== lang) {
      setToLanguage(page.language.locale)
      i18n.changeLanguage(page.language.locale)
    }

    if (correspondingTranslation && correspondingTranslation.fullSlug !== correspondingLangSlug) {
      setCorrespondingLangSlug(correspondingTranslation.fullSlug)
    }
  })

  return (
    <Layout>
      <SEO title={page.title} lang={page.language.locale}/>
        {
            page.content &&
            <Section className="SinglePost">
                <SectionOuter>
                    {
                        page.featuredImage &&
                        (
                          (page.featuredImage.imageFile && page.featuredImage.imageFile.extension !== "svg") ?
                            <GatsbyImage fluid={page.featuredImage.imageFile.childImageSharp.fluid} alt={page.featuredImage.altText}/>
                            :
                            <img className=""
                                 src={page.featuredImage.imageFile ? page.featuredImage.imageFile.publicURL : page.featuredImage.sourceUrl}
                                 alt={page.featuredImage.altText}/>
                        )
                    }
                </SectionOuter>
                <SectionInner className="SinglePost-inner Section--vPadding u-textMaxWidth">

                    <h1 className="SinglePost-title t-h1" dangerouslySetInnerHTML={{__html: page.title}} />

                    <div className="SinglePost-body" dangerouslySetInnerHTML={{__html: page.content}} />

                </SectionInner>

            </Section>
        }


      {
        pageBuilder.map((section, index) => {
          if (!section) {
            return null
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_HeroVariantTwo") {
            return <HeroVariantTwo {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_HeroVariantOne") {
            return <HeroVariantOne {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_ProductGrid") {
            return <ProductGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_AddonGrid") {
            return <AddonGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_TestimonialCarousel") {
            return <TestimonialCarousel {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_SimpleText") {
            return <SimpleText {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_Team") {
            return <Team {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_FaqGrid") {
            return <FaqGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_CallToAction") {
            return <CallToAction {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_ArticleGrid") {
            return <ArticleGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_ProjectGrid") {
            return <ProjectGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_VideoTeaser") {
            return <VideoTeaser {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_ComponentGrid") {
            return <ComponentGrid {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_PricingTable") {
            return <PricingTable {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_PricingTableSupport") {
            return <PricingTableSupport {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_LeftRightTeaser") {
            return <LeftRightTeaser {...section} key={index}/>
          }
          if (section.fieldGroupName === "page_Pagebuilder_Content_IframeEmbed") {
            return <IframeEmbed {...section} key={index}/>
          }

          return <div className="u-textCenter u-padding-md">In AllComponents fehlt das Mapping der Komponente. {section.fieldGroupName}</div>

        })

      }
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    correspondingLangSlug: state.correspondingLangSlug,
  }
}

const mapDispatchToProps = {
  setCorrespondingLangSlug,
  setToLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
