import React from "react"

import "./LeftRightTeaser.scss"

import SectionInner from "../../components/section-inner"
import Section from "../../components/section"
import LeftRightCard from "../../components/left-right-card/left-right-card"
import { getImage } from "../../helper"

const LeftRightTeaser = ({ title, text, image, imageIsLeft, infoItems }) => {
  return (
    <Section className="LeftRightTeaser Section--vPadding">
      <SectionInner className="LeftRightTeaser-inner">
          <LeftRightCard title={title} content={text} image={getImage(image)} infoItems={infoItems} variant={imageIsLeft ? 'right' : 'left'} actionButton={true} />
      </SectionInner>
    </Section>
  )
}

export default LeftRightTeaser
